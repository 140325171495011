<template>
  <section :key="folderKey" v-if="loaded" class="mt-2 select-files">

    <b-form-select
      v-if="applicationList.length != 1"
      v-model="application_uuid"
      :options="applications"
    ></b-form-select>

    <b-dropdown
      :text="currentFolder"
      block
      menu-class="w-100 form-control-border"
      toggle-class="text-left"
      class="m-2"
      v-if="application_uuid == ''"
    >
      <template #button-content>
        <span v-if="parent_uuid == ''">{{ $t("SELECT_FOLDER") }}</span>
        <span v-if="parent_uuid != '' && currentFolder != ''"><i class="fal fa-folder"></i> {{ Truncate(currentFolder, 55) }} </span>
      </template>
      <b-dropdown-item
        href="#"
        class="clickable"
        @click="goBackOneLevel"
        v-if="parent_uuid != ''"
        >{{ $t("BACK") }}</b-dropdown-item
      >
      <b-dropdown-item
        href="#"
        class="clickable"
        v-for="(item, index) in folderItems"
        :key="index"
        @click="clickedFolder(item.folder_uuid)"
        ><i class="fal fa-folder"></i>
        {{ Truncate(item.name, 55) }}</b-dropdown-item
      >
    </b-dropdown>

    <b-breadcrumb class="mt-1 mb-0" v-if="1 == 0">
      <b-breadcrumb-item
        href="#"
        v-for="(item, index) in breadcrumbs"
        :key="index"
        :active="item.active"
        @click="clickedFolder(item.folder_uuid)"
        >{{ item.text }}</b-breadcrumb-item
      >
    </b-breadcrumb>

    <div v-if="folderItems.length == 0">{{ $t("FILESTORAGE.NO_FOLDERS_TO_SAVE_FROM") }}</div>

    <b-list-group class="mt-1" v-if="filteredFiles.length != 0">
      <b-list-group-item
        v-for="(item, index) in filteredFiles"
        :key="index"
        v-b-tooltip.hover
        :title="item.name"
      >
        <b-button size="sm" class="mr-2" @click="AddFile(item)">{{ $t("ADD_FILE") }}</b-button
        ><i class="fal fa-file"></i> {{ Truncate(item.name, 55) }}
      </b-list-group-item>
    </b-list-group>
  </section>
</template>
<script>
export default {
  props: {
    filter: {
      default: () => [],
      type: Array,
    },
    active: {
      default: "",
      type: String,
    },
    fileType: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      loaded: false,
      applicationList: [],
      loading: false,
      folderItems: [],
      files: [],
      crumbs: [],
      parent_uuid: "",
      application_uuid: "",
    };
  },
  methods: {
    getApplicationFolders: function () {
      let self = this;
      this.loading = true;
      let ListFoldersRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: this.user_uuid,
        parent_uuid: "",
        section: "APPLICATION",
        search_text: "",
        page: 1,
        limit: 2000,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/list",
          ListFoldersRequestDTO
        )
        .then((response) => {
          self.redrawIndex = self.redrawIndex + 1;
          self.applicationList = response.data.items.map(function (x) {
            return { text: x.name, value: x.folder_uuid };
          });
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    clickedFolder(folder_uuid) {
      this.parent_uuid = folder_uuid;
      this.getFolders();
    },
    goBackOneLevel() {
      this.parent_uuid = this.breadcrumbs.slice(-2, -1)[0].folder_uuid;
      this.getFolders();
    },
    getFolders: function () {
      let self = this;
      this.loading = true;
      let ListFoldersRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: this.user_uuid,
        parent_uuid: this.parent_uuid,
        search_text: "",
        page: 1,
        limit: 2000,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/list",
          ListFoldersRequestDTO
        )
        .then((response) => {
          self.redrawIndex = self.redrawIndex + 1;
          self.crumbs = response.data.breadcrums;
          self.folderItems = response.data.items.map((element) => {
            element.type = "folder";
            return element;
          });
          self.getFiles();
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getFiles: function () {
      let self = this;
      this.loading = true;
      let ListFilesRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: this.user_uuid,
        folder_uuid: this.parent_uuid,
        search_text: "",
        page: 1,
        limit: 2000,
        sortBy: "NAME",
        sortDesc: false,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/file/list",
          ListFilesRequestDTO
        )
        .then((response) => {
          if(self.fileType != "")
          {
            let filetypes = self.fileType.split(',');
            self.files = response.data.items.filter(function(item) {              
              let extension = item.name.substring(item.name.lastIndexOf('.'), item.name.length)
              return filetypes.includes(extension);
              /*for(let ix = 0; ix < filetypes.length ; ix++)
              {
                if(extension == filetypes[ix])
                {
                  return true;
                }
              }
              return false;*/
            });
            self.loaded = true;
          }else{
            self.files = response.data.items;
            self.loaded = true;
          }
        })
        .catch(() => {
          self.loading = false;
          self.loaded = true;
        });
    },
    AddFile(item){
      this.$emit("selected", item);
    }
  },
  computed: {
    currentFolder() {
      if(this.breadcrumbs.length != 0)
      {
        let item = this.breadcrumbs.slice(-1);
        return item[0].text;
      }else{
        return "";
      }
    },
    breadcrumbs() {
      //let self = this;
      let currentName = "";
      /*let currentName = this.applications.filter(function (item) {
            return item.folder_uuid == self.parent_uuid;
        });*/
      let items = [];
      for (let ix = 0; ix < this.crumbs.length; ix++) {
        let crumb = this.crumbs[ix];
        if (crumb.name == "root") {
          items.push({
            folder_uuid: "",
            text: currentName,
            active: this.crumbs.length - 1 == ix,
          });
        } else {
          items.push({
            folder_uuid: crumb.value,
            text: crumb.name,
            active: this.crumbs.length - 1 == ix,
          });
        }
      }
      return items;
    },
    applications() {
      let list = [];
      list.push({ text: this.$t("FILESTORAGE.FILES"), value: "" });
      return list.concat(this.applicationList);
    },
    folderKey() {
      return "listFolders:" + this.value;
    },
    filteredFiles(){
      let filterNames = this.filter.map(
          (a) => a.name
      );
      return this.files.filter(function(item) {
        return !filterNames.includes(item.name);
      });
    }
  },
  watch: {
    application_uuid: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.parent_uuid = this.application_uuid;
        this.getFolders();
      }
    },
    parent_uuid: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.newVal == " .. ") {
          this.goBackOneLevel();
        }
        this.getFiles();
      }
    },
  },
  mounted: function () {
    this.getFolders();
    this.getApplicationFolders();
  },
};
</script>
<style>
</style>
