var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "section",
        { key: _vm.folderKey, staticClass: "mt-2 select-files" },
        [
          _vm.applicationList.length != 1
            ? _c("b-form-select", {
                attrs: { options: _vm.applications },
                model: {
                  value: _vm.application_uuid,
                  callback: function ($$v) {
                    _vm.application_uuid = $$v
                  },
                  expression: "application_uuid",
                },
              })
            : _vm._e(),
          _vm.application_uuid == ""
            ? _c(
                "b-dropdown",
                {
                  staticClass: "m-2",
                  attrs: {
                    text: _vm.currentFolder,
                    block: "",
                    "menu-class": "w-100 form-control-border",
                    "toggle-class": "text-left",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _vm.parent_uuid == ""
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("SELECT_FOLDER"))),
                                ])
                              : _vm._e(),
                            _vm.parent_uuid != "" && _vm.currentFolder != ""
                              ? _c("span", [
                                  _c("i", { staticClass: "fal fa-folder" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.Truncate(_vm.currentFolder, 55)
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3273846033
                  ),
                },
                [
                  _vm.parent_uuid != ""
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "clickable",
                          attrs: { href: "#" },
                          on: { click: _vm.goBackOneLevel },
                        },
                        [_vm._v(_vm._s(_vm.$t("BACK")))]
                      )
                    : _vm._e(),
                  _vm._l(_vm.folderItems, function (item, index) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: index,
                        staticClass: "clickable",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.clickedFolder(item.folder_uuid)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-folder" }),
                        _vm._v(
                          "\n      " + _vm._s(_vm.Truncate(item.name, 55))
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          1 == 0
            ? _c(
                "b-breadcrumb",
                { staticClass: "mt-1 mb-0" },
                _vm._l(_vm.breadcrumbs, function (item, index) {
                  return _c(
                    "b-breadcrumb-item",
                    {
                      key: index,
                      attrs: { href: "#", active: item.active },
                      on: {
                        click: function ($event) {
                          return _vm.clickedFolder(item.folder_uuid)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.text))]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.folderItems.length == 0
            ? _c("div", [
                _vm._v(_vm._s(_vm.$t("FILESTORAGE.NO_FOLDERS_TO_SAVE_FROM"))),
              ])
            : _vm._e(),
          _vm.filteredFiles.length != 0
            ? _c(
                "b-list-group",
                { staticClass: "mt-1" },
                _vm._l(_vm.filteredFiles, function (item, index) {
                  return _c(
                    "b-list-group-item",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      key: index,
                      attrs: { title: item.name },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: { size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.AddFile(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("ADD_FILE")))]
                      ),
                      _c("i", { staticClass: "fal fa-file" }),
                      _vm._v(
                        " " + _vm._s(_vm.Truncate(item.name, 55)) + "\n    "
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }